import SVG from 'components/SVG'
import React from 'react'

const House = (props) => {
  return (
    <SVG viewBox="0 0 76 33" {...props}>
      <g>
        <polyline points="76 32.47 54.99 32.47 54.99 9.33 45.63 9.41 45.63 1.41 38.63 1.41 38.63 4.41 28.63 4.41 28.63 13.41 25.63 13.41 25.63 8.41 19.63 12.41 19.63 32.47 0.63 32.47" fill="none" stroke="#c63e45" />
      </g>
    </SVG>
  )
}

export default House
