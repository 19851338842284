import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Text,
  Checkbox,
  Circle,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Link from 'components/Link'
import BlockModule from 'components/BlockModule'

import useHeader from 'contexts/header/useHeader'
import { responsive } from 'contexts/responsive'

import bg from './bg.png'
import House from './House'
import Heart from './Heart'

const StyledCheckbox = styled(Checkbox)`
  .chakra-checkbox__control {
    background-color: #dfe0e0;
    color: #c63e45 !important;
  }
`

const Pair = ({ location }) => {
  const { setHideHeader } = useHeader()
  const [care, setCare] = useState([])
  useEffect(() => {
    setHideHeader(false)
    if (!location.state?.important) navigate('/')
  }, [])
  const options = useMemo(() => location.state?.important.reduce((res, data) => {
    res.push(...data.values)
    return res
  }, []), [location.state?.important])
  return (
    <BlockModule bg={bg} percent={17.5} repeat="repeat-x">
      <Container
        maxW={responsive('30em', '43.25em')}
        px="2.875em"
        pt={responsive('2.5em', '4em')}
        pb="3.5em"
        sx={{
          "@media screen and (max-width: 368px)": {
            px: '1.625em',
            pt: '4em'
          }
        }}
      >
        <Box pos="relative" color="textColor">
          <Flex mb="0.75em" alignItems={'center'}>
            <House width="4.75em" />
            <Heart width="3.75em" />
          </Flex>
          <Text fontWeight={700} fontSize={responsive('1.375em', '1.5em')}>
            選擇你最在意的三件事
          </Text>
          <Text
            mt="0.375rem"
            fontWeight={500}
            fontSize={responsive('1em', '1.125em')}
          >
            你可以從剛剛選擇的指標中，挑選0-3個你最在意的指標
          </Text>
          <Box pos="relative">
            <Box
              pt="0.75em"
              pb="1.25em"
              height={responsive('45vh', '55vh')}
              overflow={'auto'}
            >
              {/* <Flex
                borderBottom="1px solid"
                pb="0.75em"
                px="0.25rem"
                color="textColor"
                alignItems={'center'}
              >
                <Box
                  flex="1"
                  fontWeight={700}
                  letterSpacing="1.8px"
                  fontSize={responsive('1.25rem', '1.375em')}
                  textAlign="left"
                >
                  你選擇的所有指標
                </Box>
                <Circle
                  lineHeight={1}
                  bg="textColor"
                  color="white"
                  fontWeight={500}
                  fontSize={'0.75rem'}
                  height="1.625rem"
                  width="1.625rem"
                >
                  {options?.length}
                </Circle>
              </Flex> */}
              <Box px="0.25rem">
                {options?.map((opt, i) => (
                  <Box my="0.5rem" key={i}>
                    <StyledCheckbox
                      colorScheme={'checkboxBg'}
                      isDisabled={care.length === 3 && !care.includes(opt)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          care.push(opt)
                        } else {
                          care.splice(care.findIndex(d => d === opt), 1)
                        }
                        setCare([...care])
                      }}
                      color="careGray"
                    >
                      {opt}
                    </StyledCheckbox>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              height="8vh"
              pointerEvents={'none'}
              pos={'absolute'}
              left={0}
              right={0}
              bottom={0}
              bgGradient="linear(to-b, rgba(255,255,255,0), rgba(255,255,255,0.8) 25%)"
            />
          </Box>
        </Box>
      </Container>
      <Box textAlign={'center'} position="absolute" left="50%" transform={'translateX(-50%)'} bottom={'1.875em'} zIndex={2}>
        <Button
          as={Link}
          width="80%"
          to="/loading"
          state={{ ...location.state, care }}
        >
          查看結果
        </Button>
      </Box>
    </BlockModule>
  )
}

export default Pair
