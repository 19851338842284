import React from 'react'
import SVG from 'components/SVG'

const Heart = ({ next , dasharray, dashoffset, ...props }) => {
  return (
    <SVG viewBox="0 0 60 33" {...props}>
      <g>
        <path d="M59.63,32.51h-28l12.17-12,0,0c.15-.14.3-.26.45-.41A11.45,11.45,0,0,0,28,3.84c-.14.14-.27.3-.4.45l0,0,0,0c-.14-.15-.26-.31-.41-.45A11.45,11.45,0,0,0,11,20c.14.15.3.27.45.41l0,0,12.06,12H0" fill="none" strokeDasharray={dasharray || 0} stroke-dashoffset={dashoffset} stroke="currentColor" stroke-miterlimit="10">
        {/* {next && (
          <animate
            attributeName="stroke-dashoffset"
            dur="3s"
            values="-160;0"
            calcMode="linear"
            // repeatCount="indefinite"
          />
        )} */}
        </path>
      </g>
    </SVG>
  )
}

export default Heart
